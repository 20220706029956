module.exports = {
  GATSBY_HASURA_GRAPHQL_URL:
    "https://mg-notifications.herokuapp.com/v1/graphql",
  GATSBY_HASURA_GRAPHQL_ADMIN_SECRET: "KennyRogers2017",
  GATSBY_AES_KEY:
    "cHVsc2VwbGFjZWdyaXRodWJoZWFkc3BhY2V2aXNpb25ib2FyZHRlY2hkZWNr",
  GATSBY_FIREBASE_DOCTOR_ROLE_ID: "CjysZuKQYpgXncdMVudA",
  GATSBY_ZENDESK_API_URL: "https://mgbm.zendesk.com/api/v2",
  GATSBY_ZENDESK_API_KEY: "R6ASc3FEBtXto2uSaHnzx0EG04Z78z0f2kmYtU6b",
  GATSBY_ZENDESK_EMAIL: "pap@medgrocer.com",
  GATSBY_MINIMUM_FILES_UPLOADED: 6,
  GATSBY_CONTACT_NUMBER: "0917 835 6313",
  GATSBY_CONTACT_EMAIL: "talktodoc@medgrocer.com",
  GATSBY_TELERIVET_CLOUD_FUNCTION_URL:
    "https://asia-east2-medgrocer-develop.cloudfunctions.net/telerivet",
  GATSBY_TELERIVET_API_KEY: "2sxR9_RMHZvYTVlu4PxWk8eIf1TI8rMuSJRf",
  GATSBY_TELERIVET_PROJECT_ID: "PJc49a1be2de3c517d",
  GATSBY_TELERIVET_ROUTE_ID: "PN612b4d637b264eb7",
  GATSBY_FORM_FIELD_ZENDESK_ID: "900010227363",

  GASTBY_DOCTOR_ENCRYPTED_KEY: "U2FsdGVkX18I+vAXq/uXm6wioWN1H5JVBRoylCY2lP0=",
  GATSBY_DOCTOR_ENCRYPTED_VALUE: "U2FsdGVkX19JFlJM9ldjfAK32oQEdV/GEAYCiTFHcsw=",
  GATSBY_DOCTOR_ENROLLMENT_FIELD: "900010341026",
}
