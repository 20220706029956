import React, { useEffect } from "react"
import Img from "gatsby-image"
import classNames from "classnames"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../Layout/Layout"
import Container from "layout/Container"
import { Contact } from "elements/Contact"

import { GASTBY_ENCRYPTED_KEY } from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/complete__icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    sessionStorage.removeItem(GASTBY_ENCRYPTED_KEY)
  }, [])

  const { module, completed, nextPath } = props.pageContext
  const complete = data.complete.childImageSharp.fluid

  return (
    <Layout title={module.title || "Thank You!"} seoTitle={`Complete`}>
      <Container isCentered desktop>
        <div className={classNames("columns is-vcentered")}>
          <div className="column is-5">
            <figure className={classNames("image mx-3")}>
              <Img fluid={complete} alt="Success!" />
            </figure>
          </div>
          <div className="column">
            <div
              dangerouslySetInnerHTML={{
                __html: completed.replace(/\n/g, "<br />"),
              }}
            ></div>
            <p className="mt-2">
              Feel free to reach us at <Contact /> for any questions.
            </p>
          </div>
        </div>
        <center>
          <Link to={nextPath} className="mt-2 button is-primary is-medium">
            Back to Home Page
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
